@import url("../assets/fonts/styles.css");

* {
    box-sizing: border-box;
}

body {
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
}

body p {
    line-height: 1.92;
    font-family: "Poppins", sans-serif;
    font-size: 14px !important;
}
span,td{
    font-size: 14px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", sans-serif;
}
h6 {
    font-size: 15px !important;
    
}
.h50{
    font-size: 17px !important;
}
.h10{
    font-size: 80px !important;
}
a {
    text-decoration: none !important;
}
.fw-1{
    font-weight: 400 !important;
}
.fw-2{
    font-weight: 500 !important;
}
.fw-3{
    font-weight: 600 !important;
}

.font-helvetica {
    font-family: "Helvetica Neue", sans-serif;
}
.css-46gm80-control{
    border-radius: 0 !important;
    padding: 1px !important;
}
.table thead>tr>th {
    vertical-align: bottom !important;
    border-bottom: 2px solid hsl(0, 0%, 87%) !important;
 }

a:hover {
    text-decoration: none;
}
.br-1{
    border-radius: 50px !important;
}
img {
    width: 100%;
}
.btn-1 {
    background-color: #43425d !important;
    border: 1px solid #43425d !important;
    outline: none !important;
    box-shadow: none !important;
}
.btn-x1 {
    border-radius: 9px;
    box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.11) !important;
    background-color: #1d5cc3 !important;
}
.text-1{
    color: #3b86ff !important;
}
.text-2{
    color: #43425D !important;
}
.text-3{
    color:  rgba(68, 68, 68, 0.7) !important;
}
.height-80{
   min-height: 80vh !important;
}
.iconWrapper{
    width: 40px;
    height: 40px;
    border: 1px solid;
    border-radius: 50%;
    // padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
thead{
    background: #f5f6fa;
}
.color-1 {
    color: #43425d !important;
}
.color-2 {
    color: #463c74 !important;
}
.color-3
{
    color: #444444 !important;
}
.bg-4{
    background-color: #69e4a6 !important;
}
.bg-2{
    background-color: #ff7285 !important;
}
.bg-5{
    background-color: #fff5e6 !important;
}
.bg-6{
    background-color: #43425d !important;
}
.bg-7{
    background-color: #1d5cc3 !important;
}
.bg-8{
    background-color: #F0F2F8 !important;
}

.bg-3{
    background-color: #d6e5f852;
}
.bg-9{
    background-color: white !important;
    border:1px solid #43425D !important;
}
.mb-7{
    margin-bottom: 15rem;
}
.h-100 {
    min-height: 100%!important;
}
::-webkit-scrollbar {
    width: 20px !important;
}

.bg-1{
    border-radius: 9px;
    box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.11);
    background-color: #e2e1e7;
}
.border22{
    border-style: dotted !important;
}
.Login input::-webkit-input-placeholder {
     color: #fff;
    font-size: 15px !important;
}
.Login input:-moz-placeholder {
    color: #fff;
    font-size: 12px !important;
}
.btn-2 {
    background-color: #ffffff !important;
    border: 1px solid #ffffff;
}
.dropdown-5 {
    color: #000 !important;
    background-color: transparent !important;
    border-color: transparent !important;
    font-size: 14px;
    outline: none !important;
    box-shadow: none !important;
}
.dropdown-6 {
    color: #000 !important;
    background-color: transparent !important;
    // border-color: transparent !important;
    font-size: 14px;
    outline: none !important;

    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04) !important;
}
.dropdown-5 :hover {
    color: #000 !important;
    background-color: transparent !important;
    border-color: transparent !important;
}
.dropdown-item {
    font-size: 14px;
}

.clearfloat {
    zoom: 1;
}

.clearfloat:before,
.clearfloat:after {
    content: "";
    display: table;
}

.clearfloat:after {
    clear: both;
}

input {
    color: #fff;
    box-shadow: none !important;
    outline: none !important;
}

input:-webkit-autofill {
    color: #ffff;
}
input:autofill {
    background-color: #325a37 !important;
    color: #fff;
}

input:focus-visible {
    // border-color: white !important;
}
.bzejeY div:first-child,.dHybYO div:first-child{
      white-space: break-spaces !important;
}

.select{
    background: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    // color: white !important;
    border-bottom: 2px solid #444444;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}
.css-1s2u09g-control{
    background: transparent !important;
    border: none !important;
    border-bottom: 1px solid #444444 !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

// ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
//     color: #444444;
//     opacity: 1; /* Firefox */
//   }
  
//   :-ms-input-placeholder { /* Internet Explorer 10-11 */
//     color: #444444;
//   }
  
//   ::-ms-input-placeholder { /* Microsoft Edge */
//     color: #444444;
//   }
  .background{
      right: 0;
      top:10%;
  }
//   .side1{
//      background-image: url("../assets/images/signupdesign.png") !important;
//     //  background-position: center;
//     background-size: contain !important;
//     background-repeat: no-repeat !important;
//   }
/*--------- Main Style -------*/

.access-account a {
    color: #463c74;
}

.access-account a {
    font-size: 1.5em;
}
.rounded {
    border-radius: 4.25rem !important;
}
.access-account a:hover {
    text-decoration: none;
}

.header-discounts {
    position: fixed;
    z-index: 2;
    a {
        font-weight: 600;
        letter-spacing: 1px;
    }
}

.logo img {
    max-width: 200px;
}

.page-background {
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}
.layer {
    content: "";
    position: absolute;
    background-color: #463c74;
    top: 0;
    bottom: -200px;
    left: 0;
    right: 0;
    opacity: 1;
    // z-index: -1;
}

.navigation {
    z-index: 1;
    position: relative;
}

.start-by-registering {
    background-color: #463c74;
    padding: 15em 10em;
    position: relative;
}
.registering-cta {
    position: absolute;
    top: 1em;
    background-color: #ffc107;
    left: -5em;
    font-size: 2em;
}

.introduction-copy p {
    font-size: 2em;
    color: #463c74;
}

.join p,
.choose-account p {
    font-size: 2em;
    line-height: 1em;
}

.join .btn {
    padding: 0.7rem 2rem;
}

.owl-carousel .item img {
    width: 100px !important;
    margin: 0 auto;
}

.search-wrapper {
    /* position: absolute; */
    /* top: 50%; */
    right: 0;
    left: 0;
    margin: auto;
}

.search-wrapper .search_box {
    max-width: 710px;
    width: 100%;
    background: #fff;
    // border-radius: 50px;
    height: 65px;
    display: flex;
    padding: 15px;
    // box-shadow: 0px 2px 10px -4px #b3c6ff;
    margin: auto;
    border: none !important;
    border-bottom: 1px solid #0000001f !important;
}

.search-wrapper .search_box .dropdown {
    width: 150px;
    border-right: 2px solid #dde2f1;
    color: #9fa3b1;
    position: relative;
    cursor: pointer;
}

.search-wrapper .search_box .dropdown .default_option {
    text-transform: uppercase;
    padding: 13px 15px;
    font-size: 14px;
}

.search-wrapper .search_box .dropdown ul {
    position: absolute;
    top: 56px;
    left: -10px;
    background: #fff;
    width: 150px;
    border-radius: 5px;
    padding: 20px;
    display: none;
    box-shadow: 8px 8px 6px -10px #b3c6ff;
}

.search-wrapper .search_box .dropdown ul.active {
    display: block;
}

.search-wrapper .search_box .dropdown ul li {
    padding-bottom: 20px;
    list-style: none;
}

.search-wrapper .search_box .dropdown ul li:last-child {
    padding-bottom: 0;
}

.search-wrapper .search_box .dropdown ul li:hover {
    color: #463c74;
}

.search-wrapper .search_box .dropdown:before {
    content: "";
    position: absolute;
    top: 18px;
    right: 20px;
    border: 8px solid;
    border-color: #463c74 transparent transparent transparent;
}

.search-wrapper .search_box .search_field {
    width: 100%;
    height: 100%;
    position: relative;
}

.search-wrapper .search_box .search_field .input {
    width: 100%;
    height: 100%;
    border: 0px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 38px;
    color: #463c74;
    border: none;
    outline: none;
}

.search-wrapper .search_box .search_field .fas {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 22px;
    color: #463c74;
    cursor: pointer;
}

// ::-webkit-input-placeholder {
//     /* Chrome/Opera/Safari */
//     color: #444444 ;
// }
// ::-moz-placeholder {
//     /* Firefox 19+ */
//     color: #444444 ;
// }
// :-ms-input-placeholder {
//     /* IE 10+ */
//     color: #444444 ;
// }
.Login

.heading-title h3 {
    overflow: hidden;
    text-align: center;
}
.heading-title h3:before,
.heading-title h3:after {
    background-color: #ffffff;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}
.heading-title h3:before {
    right: 0.5em;
    margin-left: -50%;
}
.heading-title h3:after {
    left: 0.5em;
    margin-right: -50%;
}

form .heading-title h3 {
    overflow: hidden;
    text-align: center;
}
form .heading-title h3:before,
form .heading-title h3:after {
    background-color: #463c74;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}
form .heading-title h3:before {
    right: 0.5em;
    margin-left: -50%;
}
form .heading-title h3:after {
    left: 0.5em;
    margin-right: -50%;
}

.video {
    cursor: pointer;
}

.play-video .bi {
    font-size: 60px;
    color: #463c74;
}

.play-text {
    font-size: 21px;
    color: #463c74;
}
.add-to-cart .bi {
    font-size: 40px;
}

.client-sidebar {
    background-color: #ddd;
    position: relative;
}

.client-sidebar h5 {
    color: #463c74;
}
.client-sidebar h6 {
    color: #463c74;
}

.client-pricing {
    border-radius: 10px;
    bottom: 0;
}

.search-all-products .flex-fill {
    position: relative;
}

.search-page .search-wrapper .search_box {
    background: transparent;
    box-shadow: 0px 2px 10px -4px #463c74;
    max-width: 1100px;
}

.search-page .search-wrapper .search_box input {
    background: transparent !important;
}
.search-wrapper .search_box .search_field .fas {
    color: #fff;
}

.search-page {
    height: 90vh;
}

.search-list ul {
    list-style: none;
    overflow-y: scroll;
    height: 60vh;
}
.search-list a {
    color: #fff !important;
}

.search-list li {
    margin-bottom: 1em;
    font-size: 1.4em;
    font-weight: 200;
}

.close-search a .fas {
    font-size: 40px;
    cursor: pointer;
    color: #fff;
}

.product-list {
    position: relative;
}

.wishlist {
    position: absolute;
    top: 0;
}
.prododuct-logo img {
    max-width: 30px;
    width: 100%;
}

.wishlist-icon .bi {
    color: #463c74;
    font-size: 20px;
}

.offer h5 {
    padding: 5px 10px;
    background-color: #463c74;
    border-radius: 30px;
    font-size: 14px;
    color: #fff;
}
//
.Back {
    width: 35px;
    height: 20px;
    // margin: 0 0 0 24px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    // line-height: 2.79;
    letter-spacing: normal;
    text-align: left;
    color: #463c74;
}

.cursor {
    cursor: pointer;
}
.shipping-processes .bi {
    font-size: 22px;
    color: #ccc;
    border: 2px solid #ccc;
    padding: 5px 9px;
    border-radius: 50px;
}

.shipping-processes .bi.active {
    color: #463c74;
    border: 2px solid #463c74;
}

.single-product-shipping img {
    max-width: 70px;
}

.single-product-shipping-headings h6 {
    font-size: 13px;
    color: #ccc;
    letter-spacing: 1px;
}

.cart_quantity_button a {
    color: #1b1e24;
}
.icon {
    cursor: pointer !important;
}
.unclickable {
    pointer-events: none;
}
.cart_quantity_input {
    cursor: pointer;
    display: inline-block;
    width: 30px;
    height: auto;
    text-align: center;
    padding: 4.5px;
    // padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    color: #1b1e24;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e8eaf3;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

.access-account {
    position: relative;
}

.access-account .bi.bi-cart-fill {
    color: #463c74;
    background-color: #e8eaf3;
    padding: 10px 13px;
    border-radius: 50px;
    width: 30px;
    font-size: 14px;
    height: 30px;
    position: relative;
}

.access-account .added-products-to-cart {
    padding: 10px 13px;
    border-radius: 50px;
    position: relative;
}

.added-number {
    font-size: 10px;
    font-weight: 600;
    background-color: #ba0c2f;
    position: absolute;
    top: 0;
    right: -30px;
    left: 0;
    margin: 0 auto;
    color: #fff;
    border-radius: 50px;
    width: 14px;
    height: 15px;
    text-align: center;
}
select {
    background: url(../assets/icons/chevron-down.svg) no-repeat right
        transparent;
    -webkit-appearance: none;
    background-position-x: 95%;
}

.accepted .bi {
    color: #463c74;
    font-size: 70px;
}

.add-product-number {
    position: relative;
}
.dropdown-toggle{
    font-size: 15px !important;
}

ul{
    list-style-type: circle;
    li{
font-size: 14px;
margin-bottom: 1rem;
    }
}

.hover{
    transition: 0.5s all;
}
.hover:hover{
    transform: scale(1.1);
    transition: 1s all;
}

.offcanvas-start {
 
    width: 304px !important;
    
}
@media (min-width: 576px){
.modal-sm {
    max-width: 350px !important;
}
}

/*--------- Media Queries -------*/

@media (max-width: 992px) {
    .h-40 {
        min-height: 40vh!important;
    }
}

@media (max-width: 520px) {
    p {
        font-size: 1em !important;
        line-height: normal !important;
    }
    .mb-7 {
        margin-bottom: 5rem;
    }
     .table thead th {
        font-size: 12px !important;
    }
}
@media (max-width: 320px) {
    .access-account {
        font-size: 4px;
    }
}

//
.supplier-icon {
    position: absolute;
    font-size: 20px;
    bottom: 0;
    color: #c9c9c9;
}
.skeleton {
    opacity: 0.7;
    animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-text {
    width: 100%;
    height: 0.5rem;
    margin-bottom: 0.25rem;
    border-radius: 0.125rem;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.sc-eCImPb {
    justify-content: flex-start !important;
}
.sc-dlVxhl {
    justify-content: flex-start !important;
}
.sc-dlVxhl .sc-eCImPb:nth-child(1) {
    flex-grow: 4 !important;
    // border: 10px solid red;
}
.sc-dlVxhl .sc-eCImPb:nth-child(4) {
    flex-grow: 2 !important;
    // border: 10px solid red;
}
