.auth_header {
    .auth_header__acc {
        .auth_header__acc__desc {
            font-size: 1rem;
            color: #463c74;
        }
        .auth_header__acc__login a {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 1.5px;
        }
    }
}
